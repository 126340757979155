.company-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px !important;
    line-height: 115% !important;
    color: #152536 !important;
}

.company-status {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 28px !important;
}

.header-cell {
    border-bottom: 2px solid #DEE2E6 !important;
    border-top: 1px solid #DEE2E6 !important;
}

.description-table-cell {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: #68717A !important;
}

.detail-table-cell {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.button-active-inactive {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #343A40 !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 11px 16px !important;
    text-transform: capitalize !important;
    size: 25px !important;
}

.button-active-inactive svg path {
    fill: #54595E !important;
}

.button-active-inactive svg {
    margin-right: 5px !important;
}

.button-back {
    display: flex;
    align-items: center;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 27.9915px !important;
    line-height: 39px !important;
    color: #231E60 !important;
    text-transform: capitalize !important;
}

.row-org-detail {
    border-bottom: 1px solid #DEE2E6 !important;
}

.row-org-detail .css-1ex1afd-MuiTableCell-root {
    border-bottom: 0 !important;
}

.row-org-detail .css-q34dxg {
    border-bottom: 0 !important;
}

.button-edit-client {
    background: #F8F9FA !important;
    border-radius: 4px !important;
    width: 78px !important;
    height: 39px !important;
    color: #343A40 !important;
    text-transform: capitalize !important;
}