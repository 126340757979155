.label-not-same-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #DC3545 !important;
    margin-top: 3% !important;
}

.label-generate-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}

.coupon-info>div {
    display: flex;
    width: calc(33% - 24px);
    margin: 0px 10px;
    margin-bottom: 10px !important;
    flex-direction: row;
    /* flex-wrap: nowrap; */
}
td.table-row-name:hover {
    background-color: #27272717;
    /* opacity: 0.7; */
    /* display: inline-block; */
    cursor: pointer;
}
.coupon-info:not(.titles) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    line-height: 41px;
}

.coupon-info {
    padding-left: 20%;
}

.coupon-info.titles {
    display: flex;
}

.duration-titles, .coupons-display {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
}

.column {
    width: calc(14% - 10px);
    /* width: 10px; */
}

.category.column {
    text-align: center;
    font-size: 20px;
}

.coupons .column:not(.category) {
    opacity: 0.8;
    cursor: pointer;
}

.coupons .column:not(.category):hover {
    color: #5AB6DF;
    opacity: 1;
}

.duration-spec.column {
    text-align: inherit;
}

.cupon-header p {
    width: 40%;
    font-size: 32px !important;
    background: #8976B690 !important;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 0px 5px #27272727;
}