.label-reset-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #54595E !important;
}

.button-reset-password {
    width: 94px !important;
    height: 42px !important;
    background-color: #5AB6DF !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-top: 2% !important;
}

.button-reset-password:hover {
    background-color: #54afd6 !important;
}