.MuiPaginationItem-root {
    width: 34px !important;
    height: 40px !important;
    background: #FFFFFF !important;
    border: 1px solid #DEE2E6 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    color: #5AB6DF !important;
}

.MuiPagination-ul li .Mui-selected {
    background: #5AB6DF !important;
    border: 1px solid #5AB6DF !important;
    color: #FFFFFF !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}