.label-not-same-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #DC3545 !important;
    margin-top: 3% !important;
}

.label-generate-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}

.plan-prices>div {
    display: flex;
    width: calc(20% - 24px);
    margin: 0px 10px;
    margin-bottom: 10px !important;
    flex-direction: row;
    /* flex-wrap: nowrap; */
}

.plan-prices:not(.titles) {
    display: flex;
    flex-direction: row;
    line-height: 41px;
    align-items: flex-start;
}
.plan-prices {
    padding-left: 20%;
}

.plan-prices:not(.titles)>div:not(:first-of-type):not(:last-of-type):before {
    content: "$";
    position: relative;
    opacity: 0.75;
}

.plan-prices.titles {
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    width: 80%;
}

.plan-row {
    display: flex;
    align-items: center;
}

.plan-row .label-app{
    width: 100px;
}