.form-radio-container {
    margin-right: 25px;
}

.form-radio {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white;
    border: solid 1px #ABB5BE;
    width: 18px;
    height: 18px;
    float: left !important;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}

.form-radio:checked {
    background: #5AB6DF;
    position: relative;
    display: inline-block;
    border: solid 1px #5AB6DF;
}

.form-radio-disabled:checked {
    background: #6C757D;
    position: relative;
    display: inline-block;
    border: solid 1px #6C757D;
}

.form-radio:checked::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../../assets/Icons/checkbox.svg') no-repeat;
    top: 2px !important;
    left: 2px !important;
}

.form-radio-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #68717A;
}