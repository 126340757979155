.SwitchToggle {
    position: relative;
    display: inline-block;
    width: 38.4px;
    height: 24px;
}

.SwitchToggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0px;
    bottom: 0;
    background-color: #5AB6DF;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-toggle:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider-toggle {
    background-color: #5AB6DF;
    position: absolute;
}

input:focus+.slider-toggle {
    box-shadow: 0 0 1px #CED4DA;
}

input:checked+.slider-toggle:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded slider-toggles */

.slider-toggle.round {
    border-radius: 34px;
}

.slider-toggle.round:before {
    border-radius: 50%;
}