.user-name-navbar {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #442F91 !important;
}

.user-image-navbar {
    width: 48px !important;
    height: 48px !important;
}

.menu-item-profile-navbar {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #6C757D !important;
}

.menu-item-profile-navbar:hover, .menu-item-profile-navbar:hover svg path {
    color: #17ACEA !important;
    fill: #17ACEA !important;
}