:root {
  --font-family-roboto: 'Roboto', sans-serif;
}

* :not(.text span, .wrapper span) {
  font-family: var(--font-family-roboto) !important;
}

body {
  margin: 0;
  background-color: #F8F9FA;
}

.cap-first-letter {
  text-transform: "capitalize" !important;
}

.title-app {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 27.9915px !important;
  line-height: 39px !important;
  color: #231E60 !important;
}
.mb1 {
  margin-bottom: 25px;
}
.label-app {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #152536 !important;
}

.container-app-div {
  border-radius: 20px;
  background-color: #FFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-top: 33px;
  margin-bottom: 24px;
  padding: 28px
}

.container-app-div:nth-last-child(2) {
  margin-bottom: 32px;
}

.button-app-save {
  min-width: 94px !important;
  height: 42px !important;
  background-color: #5AB6DF !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
}

.button-app-delete {
  min-width: 94px !important;
  height: 42px !important;
  background-color: rgb(236, 48, 48) !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #FFFFFF !important;
  text-transform: initial !important;
}

.button-app-cancel {
  min-width: 94px !important;
  height: 42px !important;
  background: #6C757D !important;
  border: 1px solid #909eaa !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
}

.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.item-sidebar {
  background: #8976B6 !important;
  color: #FFF !important;
}

.app-form-title {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #68717A !important;
  margin-bottom: 23px !important;
}

.app-form-subtitle {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #6C757D !important;
}

.button-app-clear-filter {
  min-width: 94px !important;
  height: 42px !important;
  background: #fff !important;
  border: 1px solid #6C757D !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #6C757D !important;
  text-transform: capitalize !important;
}

.button-app-create {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #5AB6DF !important;
  text-transform: initial !important;
  align-items: flex-start !important;
  padding-top: 0 !important;
}

.button-app-create:hover {
  background-color: transparent !important;
}

.button-app-see-detail {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #442F91 !important;
  text-transform: capitalize !important;
}

.table-app-title-header {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #152536 !important;
  border-bottom: 2px solid #DEE2E6 !important;
}

.app-error-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px;
  line-height: 17px;
  color: #DC3545;
  margin-top: 2% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiButton-root.MuiButton-contained.MuiButtonBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.button-app-previous {
  min-width: 94px !important;
  height: 42px !important;
  background-color: #8976B6 !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
}


.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #8976B6;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #9e88d1;
}

.scroll::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover, .scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}